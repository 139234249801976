*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

h1, h2, h3, h4 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

h1, h2, h3, h4, p {
  margin: 0;
}

textarea {
  resize: vertical;
}

button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg {
  vertical-align: middle;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter, ui-sans-serif, sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

main {
  height: 96vh;
  scroll-snap-type: y var(--tw-scroll-snap-strictness);
}

main section:first-of-type {
  margin-top: 56px;
}

@media (min-width: 1024px) {
  main section:first-of-type {
    margin-top: 72px;
  }
}

main section:last-of-type {
  margin-bottom: 58px;
}

@media (min-width: 1024px) {
  main section:last-of-type {
    margin-bottom: 56px;
  }
}

.form-success {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: .75rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.form-error {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: .75rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

#home article {
  height: 96vh;
  scroll-snap-align: center;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#home article .copy-space {
  max-width: 80vw;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  #home article .copy-space {
    max-width: 60vw;
  }
}

#home article .copy-space .copy p {
  margin-top: 1rem;
  margin-bottom: .5rem;
  line-height: 1.25rem;
}

@media (min-width: 1024px) {
  #home article .copy-space .copy p {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

#home article .jump-to-action {
  position: absolute;
  bottom: 3rem;
}

#home article .jump-to-action a {
  display: flex;
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

#home article .jump-to-action a {
  scroll-snap-align: center;
  text-indent: -999rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1px;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation: 1s infinite bounce;
}

#home article .jump-to-action a svg {
  height: 3rem;
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  fill: currentColor;
}

#home article .jump-to-action a svg:hover {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

#home article .call-to-action {
  margin-top: 3rem;
}

#contact-us section {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#contact-us section .grid {
  min-height: 85vh;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 1024px) {
  #contact-us section .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
}

#contact-us section .grid article {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#contact-us section .grid article p {
  margin-top: .5rem;
  margin-bottom: .5rem;
  line-height: 1.25rem;
}

@media (min-width: 1024px) {
  #contact-us section .grid article p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

header {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
  flex-direction: row;
  order: -9999;
  justify-content: space-between;
  padding: .75rem 1.25rem;
  display: flex;
  position: fixed;
  top: 0;
}

header .branding {
  flex-direction: row;
  align-items: center;
  display: flex;
}

header .branding .product-title {
  cursor: pointer;
  letter-spacing: -.05em;
  order: 9999;
  margin-left: .5rem;
  font-family: MierB, ui-sans-serif, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

@media (min-width: 1024px) {
  header .branding .product-title {
    margin-left: .75rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 768px) {
  header .branding .product-title {
    line-height: 1;
  }
}

header .branding svg {
  height: 2rem;
  cursor: pointer;
  order: -9999;
}

@media (min-width: 1024px) {
  header .branding svg {
    height: 3rem;
  }
}

header nav {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

header nav .menu-button {
  cursor: pointer;
}

header nav .menu-button svg {
  height: 2rem;
}

header nav .menu-items {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 56px;
  right: 0;
}

@media (min-width: 1024px) {
  header nav .menu-items {
    width: auto;
    top: 72px;
  }
}

header nav .menu-items .menu-list-item {
  padding: .75rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

header nav .menu-items .menu-list-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

footer {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
  flex-direction: row;
  order: 9999;
  justify-content: space-between;
  padding: .75rem 1.25rem;
  display: flex;
  position: fixed;
  bottom: 0;
}

footer .copyright {
  width: 100%;
  justify-content: center;
  display: flex;
}

footer .copyright div {
  text-align: center;
  align-items: center;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
}

@media (min-width: 768px) {
  footer .copyright div {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

footer .back-to-start {
  text-indent: -999rem;
  font-size: 1px;
}

footer .back-to-start svg {
  height: 2rem;
  fill: currentColor;
}

footer .back-to-start svg:hover {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 0;
    padding-right: 0;
  }
}

.component.call-to-action {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.component.call-to-action .grid {
  min-height: 50vh;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
}

.component.call-to-action .grid article {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.component.call-to-action .grid article .heading-text, .component.call-to-action .grid article .copy-text {
  text-align: center;
  margin-bottom: 2.5rem;
}

.component.hero {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}

.component.hero .grid {
  min-height: 85vh;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 1024px) {
  .component.hero .grid {
    min-height: 50vh;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
}

.component.hero .grid article {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.component.hero .grid article:nth-child(2) {
  margin-left: -1rem;
  margin-right: -1rem;
}

.component.hero .grid article p {
  margin-top: .5rem;
  margin-bottom: .5rem;
  line-height: 1.25rem;
}

@media (min-width: 1024px) {
  .component.hero .grid article p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
  }
}

.m-6 {
  margin: 1.5rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-24 {
  height: 6rem;
}

.h-full {
  height: 100%;
}

.w-24 {
  width: 6rem;
}

.w-full {
  width: 100%;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-28 {
  gap: 7rem;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.bg-content {
  background-image: url("../img/bg-dragon-scales.d3057a75.svg");
}

.bg-maintenance {
  background-image: url("../img/bg-rose-petals.276af5ad.svg");
}

.bg-markup {
  background-image: url("../img/bg-shiny-overlay.e4a68fa5.svg");
}

.bg-speed {
  background-image: url("../img/bg-radial-gradient.1536cec2.svg");
}

.bg-start {
  background-image: url("../img/bg-shiny.c83a6a11.svg");
}

.bg-wordpress {
  background-image: url("../img/bg-hexagon.88de2c83.svg");
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.font-light {
  font-weight: 300;
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

h1, h2, h3, h4 {
  font-family: MierB, ui-sans-serif, sans-serif;
  font-weight: 700;
}

h1, .h1 {
  letter-spacing: -.025em;
  font-size: 1.875rem;
  line-height: 2rem;
}

@media (min-width: 1024px) {
  h1, .h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

h2, .h2 {
  letter-spacing: -.025em;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

@media (min-width: 1024px) {
  h2, .h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

h3 {
  letter-spacing: -.025em;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

@media (min-width: 1024px) {
  h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

h4 {
  letter-spacing: -.025em;
  font-size: 1.125rem;
  line-height: 1.25rem;
}

@media (min-width: 1024px) {
  h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.form-control {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: block;
}

.form-control:last-of-type {
  margin-bottom: 0;
}

.form-control label {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: none;
}

.form-control input, .form-control select, .form-control textarea {
  width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  outline-offset: 2px;
  border-radius: .5rem;
  outline: 2px solid #0000;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: block;
}

.form-control input:focus, .form-control select:focus, .form-control textarea:focus {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.btn {
  text-transform: uppercase;
  border-width: 0;
  border-radius: .25rem;
  font-weight: 700;
  display: inline-block;
}

.btn-sm {
  padding: .75rem 1.25rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.btn-lg {
  padding: 1rem 2rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.btn-xl {
  padding: 1.25rem 2.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.btn-pink {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-pink:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.btn-pink-inverse {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-pink-inverse:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/open-sans-v28-latin-300.df134ab7.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-300.73748d00.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/open-sans-v28-latin-regular.b5b03397.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-regular.a36ae95d.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/open-sans-v28-latin-500.3cb2c901.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-500.1fb063cd.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/open-sans-v28-latin-700.c5b2a342.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-700.2ceab623.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/open-sans-v28-latin-600.12dc94da.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-600.eac8e6d6.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 300;
  src: local(""), url("../fonts/open-sans-v28-latin-300italic.b08ce869.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-300italic.7f8c3020.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/open-sans-v28-latin-800.9046d0df.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-800.2961b7a8.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 400;
  src: local(""), url("../fonts/open-sans-v28-latin-italic.112c3b3e.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-italic.bef8f8fb.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 500;
  src: local(""), url("../fonts/open-sans-v28-latin-500italic.76ff3eb9.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-500italic.d80c2e32.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 600;
  src: local(""), url("../fonts/open-sans-v28-latin-600italic.69da6b68.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-600italic.e753ab42.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 700;
  src: local(""), url("../fonts/open-sans-v28-latin-700italic.0c61e89b.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-700italic.86c6a975.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-weight: 800;
  src: local(""), url("../fonts/open-sans-v28-latin-800italic.9efa761c.woff2") format("woff2"), url("../fonts/open-sans-v28-latin-800italic.4e91c539.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: local(""), url("../fonts/inter-v11-latin-100.a2a06baa.woff2") format("woff2"), url("../fonts/inter-v11-latin-100.4f050ea4.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../fonts/inter-v11-latin-200.ca20eeb4.woff2") format("woff2"), url("../fonts/inter-v11-latin-200.5b88482a.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/inter-v11-latin-300.2a713942.woff2") format("woff2"), url("../fonts/inter-v11-latin-300.e0dbf67d.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/inter-v11-latin-regular.58b66fb4.woff2") format("woff2"), url("../fonts/inter-v11-latin-regular.2326dbdf.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/inter-v11-latin-500.55c60941.woff2") format("woff2"), url("../fonts/inter-v11-latin-500.288c6b28.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/inter-v11-latin-600.6f7afce8.woff2") format("woff2"), url("../fonts/inter-v11-latin-600.e08c03cd.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/inter-v11-latin-700.e96e3018.woff2") format("woff2"), url("../fonts/inter-v11-latin-700.e0700a89.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/inter-v11-latin-800.d48dc638.woff2") format("woff2"), url("../fonts/inter-v11-latin-800.03eeead1.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/inter-v11-latin-900.b8181cdc.woff2") format("woff2"), url("../fonts/inter-v11-latin-900.dabe6065.woff") format("woff");
}

@font-face {
  font-family: MierB;
  font-style: normal;
  font-weight: 100;
  src: local(""), url("../fonts/MierB-Thin.b429e227.woff2") format("woff2"), url("../fonts/MierB-Thin.810271b0.woff") format("woff");
}

@font-face {
  font-family: MierB;
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../fonts/MierB-Light.b658f875.woff2") format("woff2"), url("../fonts/MierB-Light.a3fd3e6f.woff") format("woff");
}

@font-face {
  font-family: MierB;
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/MierB-Book.f288062d.woff2") format("woff2"), url("../fonts/MierB-Book.e1d1465d.woff") format("woff");
}

@font-face {
  font-family: MierB;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/MierB-Regular.9720fa70.woff2") format("woff2"), url("../fonts/MierB-Regular.af5c1679.woff") format("woff");
}

@font-face {
  font-family: MierB;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/MierB-DemiBold.2acb27cc.woff2") format("woff2"), url("../fonts/MierB-DemiBold.52f4fa49.woff") format("woff");
}

@font-face {
  font-family: MierB;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/MierB-Bold.2870f182.woff2") format("woff2"), url("../fonts/MierB-Bold.2f132948.woff") format("woff");
}

@font-face {
  font-family: MierB;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/MierB-ExtraBold.7e723697.woff2") format("woff2"), url("../fonts/MierB-ExtraBold.4f858492.woff") format("woff");
}

@font-face {
  font-family: MierB;
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/MierB-Black.cb8e545d.woff2") format("woff2"), url("../fonts/MierB-Black.91d37059.woff") format("woff");
}

@font-face {
  font-family: MierB;
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/MierB-Heavy.f6db0de7.woff2") format("woff2"), url("../fonts/MierB-Heavy.3d3a326f.woff") format("woff");
}

/*# sourceMappingURL=index.a013bf3a.css.map */

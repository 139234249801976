/* open-sans-300 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 300;
     src: local(""), url("../fonts/open-sans-v28-latin-300.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 400;
     src: local(""), url("../fonts/open-sans-v28-latin-regular.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-500 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 500;
     src: local(""), url("../fonts/open-sans-v28-latin-500.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 700;
     src: local(""), url("../fonts/open-sans-v28-latin-700.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 600;
     src: local(""), url("../fonts/open-sans-v28-latin-600.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-300italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 300;
     src: local(""), url("../fonts/open-sans-v28-latin-300italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800 - latin */
@font-face {
     font-family: "Open Sans";
     font-style: normal;
     font-weight: 800;
     src: local(""), url("../fonts/open-sans-v28-latin-800.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 400;
     src: local(""), url("../fonts/open-sans-v28-latin-italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-500italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 500;
     src: local(""), url("../fonts/open-sans-v28-latin-500italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 600;
     src: local(""), url("../fonts/open-sans-v28-latin-600italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 700;
     src: local(""), url("../fonts/open-sans-v28-latin-700italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800italic - latin */
@font-face {
     font-family: "Open Sans";
     font-style: italic;
     font-weight: 800;
     src: local(""), url("../fonts/open-sans-v28-latin-800italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/open-sans-v28-latin-800italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-100 - latin */
@font-face {
     font-family: "Inter";
     font-style: normal;
     font-weight: 100;
     src: local(""), url("../fonts/inter-v11-latin-100.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/inter-v11-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-200 - latin */
@font-face {
     font-family: "Inter";
     font-style: normal;
     font-weight: 200;
     src: local(""), url("../fonts/inter-v11-latin-200.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/inter-v11-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-300 - latin */
@font-face {
     font-family: "Inter";
     font-style: normal;
     font-weight: 300;
     src: local(""), url("../fonts/inter-v11-latin-300.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/inter-v11-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-regular - latin */
@font-face {
     font-family: "Inter";
     font-style: normal;
     font-weight: 400;
     src: local(""), url("../fonts/inter-v11-latin-regular.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/inter-v11-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-500 - latin */
@font-face {
     font-family: "Inter";
     font-style: normal;
     font-weight: 500;
     src: local(""), url("../fonts/inter-v11-latin-500.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/inter-v11-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-600 - latin */
@font-face {
     font-family: "Inter";
     font-style: normal;
     font-weight: 600;
     src: local(""), url("../fonts/inter-v11-latin-600.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/inter-v11-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-700 - latin */
@font-face {
     font-family: "Inter";
     font-style: normal;
     font-weight: 700;
     src: local(""), url("../fonts/inter-v11-latin-700.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/inter-v11-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-800 - latin */
@font-face {
     font-family: "Inter";
     font-style: normal;
     font-weight: 800;
     src: local(""), url("../fonts/inter-v11-latin-800.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/inter-v11-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-900 - latin */
@font-face {
     font-family: "Inter";
     font-style: normal;
     font-weight: 900;
     src: local(""), url("../fonts/inter-v11-latin-900.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/inter-v11-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/**************************************************************************/
/**************************************************************************/
/**************************************************************************/
/* mierb-100 - latin */
@font-face {
     font-family: "MierB";
     font-style: normal;
     font-weight: 100;
     src: local(""), url("../fonts/MierB-Thin.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/MierB-Thin.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mierb-200 - latin */
@font-face {
     font-family: "MierB";
     font-style: normal;
     font-weight: 200;
     src: local(""), url("../fonts/MierB-Light.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/MierB-Light.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mierb-300 - latin */
@font-face {
     font-family: "MierB";
     font-style: normal;
     font-weight: 300;
     src: local(""), url("../fonts/MierB-Book.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/MierB-Book.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mierb-regular - latin */
@font-face {
     font-family: "MierB";
     font-style: normal;
     font-weight: 400;
     src: local(""), url("../fonts/MierB-Regular.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/MierB-Regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mierb-500 - latin */
@font-face {
     font-family: "MierB";
     font-style: normal;
     font-weight: 500;
     src: local(""), url("../fonts/MierB-DemiBold.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/MierB-DemiBold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mierb-600 - latin */
@font-face {
     font-family: "MierB";
     font-style: normal;
     font-weight: 600;
     src: local(""), url("../fonts/MierB-Bold.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/MierB-Bold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mierb-700 - latin */
@font-face {
     font-family: "MierB";
     font-style: normal;
     font-weight: 700;
     src: local(""), url("../fonts/MierB-ExtraBold.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/MierB-ExtraBold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mierb-800 - latin */
@font-face {
     font-family: "MierB";
     font-style: normal;
     font-weight: 800;
     src: local(""), url("../fonts/MierB-Black.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/MierB-Black.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* mierb-900 - latin */
@font-face {
     font-family: "MierB";
     font-style: normal;
     font-weight: 900;
     src: local(""), url("../fonts/MierB-Heavy.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/MierB-Heavy.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

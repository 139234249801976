@tailwind base;
@tailwind components;
@tailwind utilities;
@import "_fonts";
@layer base {
    :root {
        @apply font-main-sans antialiased;
    }
    html {
        @apply scroll-smooth;
    }
    body {
        @apply flex flex-col justify-between;
    }
    main {
        @apply h-[96vh] snap-y;

        section:first-of-type {
            @apply mt-[56px];
            @apply lg:mt-[72px];
        }
        section:last-of-type {
            @apply mb-[58px];
            @apply lg:mb-[56px];
        }
    }
    /* uncss:ignore start */
    .form-success {
        @apply bg-gray-800 text-white text-xl font-bold rounded-lg p-3;
    }
    .form-error {
        @apply bg-red-700 text-white text-xl font-bold rounded-lg p-3;
    }
    /* uncss:ignore end */
    #home {
        article {
            @apply h-[96vh] flex flex-col justify-center items-center snap-center bg-cover relative;
            .copy-space {
                @apply max-w-[80vw] text-white;
                @apply lg:max-w-[60vw];

                .copy {
                    p {
                        @apply mt-4 leading-5;
                        @apply lg:mt-8 mb-2 lg:mb-4 lg:font-medium lg:text-xl lg:leading-8;
                    }
                }
            }
            .jump-to-action {
                @apply absolute bottom-12;

                a {
                    @apply text-white flex flex-col justify-center items-center snap-center animate-bounce ease-in-out indent-[-999rem] text-[1px];

                    svg {
                        @apply h-12 rotate-180 fill-current hover:text-cyan-100;
                    }
                }
            }
            .call-to-action {
                @apply mt-12;
            }
        }
    }

    #contact-us {
        section {
            @apply flex flex-col justify-center bg-pink-200;
            .grid {
                @apply grid-cols-1 gap-4 min-h-[85vh];
                @apply lg:grid-cols-2 lg:gap-8;
                article {
                    @apply flex flex-col justify-center text-gray-800;
                    p {
                        @apply my-2 leading-5;
                        @apply lg:my-4 lg:font-medium lg:text-xl lg:leading-8;
                    }
                }
            }
        }
    }

    header {
        @apply order-first fixed w-full top-0 flex flex-row justify-between px-5 py-3 bg-slate-50;
        .branding {
            @apply flex flex-row items-center;
            .product-title {
                @apply font-header-sans text-2xl font-bold tracking-tighter order-last ml-2 cursor-pointer;
                @apply lg:text-3xl lg:ml-3;
                @apply md:leading-none;
            }
            svg {
                @apply h-8 lg:h-12 order-first cursor-pointer;
            }
        }
        nav {
            @apply flex flex-col justify-center;
            .menu-button {
                @apply cursor-pointer;
                svg {
                    @apply h-8;
                }
            }
            .menu-items {
                @apply flex flex-col absolute right-0 top-[56px] w-full bg-slate-50;
                @apply lg:top-[72px] lg:w-auto;
                .menu-list-item {
                    @apply p-3 inline-block text-lg font-medium transition duration-500;
                    @apply hover:bg-gray-800 hover:text-white;
                }
            }
        }
    }
    footer {
        @apply order-last fixed w-full bottom-0 flex flex-row justify-between px-5 py-3 bg-slate-50;

        .copyright {
            @apply w-full flex justify-center;
            div {
                @apply text-xs text-center flex items-center;
                @apply md:text-sm;
            }
        }
        .back-to-start {
            @apply indent-[-999rem] text-[1px];
            svg {
                @apply h-8 fill-current;
                @apply hover:text-pink-900;
            }
        }
    }
}
@layer components {
    .component {
        &.call-to-action {
            @apply flex flex-col justify-center bg-pink-100;
            .grid {
                @apply grid-cols-1 gap-8 min-h-[50vh];
                article {
                    @apply mx-auto self-center flex flex-col items-center text-gray-900;
                    .heading-text,
                    .copy-text {
                        @apply mb-10 text-center;
                    }
                }
            }
        }
        &.hero {
            @apply bg-pink-900;
            .grid {
                @apply grid-cols-1 gap-4 min-h-[85vh];
                @apply lg:grid-cols-2 lg:gap-8 lg:min-h-[50vh];
                article {
                    @apply flex flex-col justify-center text-white;

                    &:nth-child(2) {
                        @apply -mx-4;
                    }

                    p {
                        @apply my-2 leading-5;
                        @apply lg:my-4 lg:font-medium lg:text-xl lg:leading-8;
                    }
                }
            }
        }
    }
}
@layer utilities {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-header-sans font-bold;
    }
    h1,
    .h1 {
        @apply leading-8 tracking-tight text-3xl;
        @apply lg:text-5xl;
    }
    h2,
    .h2 {
        @apply leading-7 tracking-tight text-2xl;
        @apply lg:text-4xl;
    }
    h3,
    .h3 {
        @apply leading-6 tracking-tight text-xl;
        @apply lg:text-3xl;
    }
    h4,
    .h4 {
        @apply leading-5 tracking-tight text-lg;
        @apply lg:text-2xl;
    }

    .form-control {
        @apply block my-6 w-full;
        @apply first-of-type:mt-0 last-of-type:mb-0;

        label {
            @apply hidden text-lg font-semibold text-gray-700;
        }
        input,
        select,
        textarea {
            @apply w-full text-lg font-semibold text-gray-700 block rounded-lg p-4 border  outline-none border-gray-300 bg-white;
            @apply focus:border focus:border-pink-700;
        }
    }
    .btn {
        @apply font-bold rounded border-0 uppercase inline-block;
    }
    .btn-sm {
        @apply text-sm py-3 px-5;
    }
    .btn-lg {
        @apply text-lg py-4 px-8;
    }
    .btn-xl {
        @apply text-xl py-5 px-10;
    }
    .btn-pink {
        @apply bg-pink-900 text-white transition duration-500;
        @apply hover:bg-pink-600;
    }
    .btn-pink-inverse {
        @apply bg-white text-pink-900 transition duration-500;
        @apply hover:bg-pink-700 hover:text-white;
    }
}
